import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major bg-image-index">
        <div className="inner">
            <header className="major">
                <h1>Hi, I'm Amanda Tjahjadi</h1>
            </header>
            <div className="content">
                <p>Welcome to my e-portfolio! </p>
                <p>San Jose State University, MS Informatics </p>
            </div>
        </div>
    </section>
)

export default Banner
