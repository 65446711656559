import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import Banner from "../components/Banner";

import pic01 from "../assets/images/compA/compa-thumb.jpg";
import pic02 from "../assets/images/compB/compb-thumb.jpg";
import pic03 from "../assets/images/compC/compc-thumb.jpg";
import pic04 from "../assets/images/compD/compd-thumb.jpg";
import pic05 from "../assets/images/compE/teamwork.jpg";
import pic06 from "../assets/images/compF/compf-thumb.jpg";

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Amanda Tjahjadi E-portfolio 2020"
          meta={[
            { name: "description", content: "Amanda Tjahjadi's e-portfolio for the MS in Informatics program at San Jose State University" },
            { name: "keywords", content: "e-portfolio, informatics" },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="intro">
            <div className="inner">
              <header className="major">
                <h2>Introduction</h2>
              </header>
              <p>
                Informatics is a broad information science field that focuses on
                analyzing data and designing information technology solutions to
                meet the needs of end users (University of Washington, 2020).
                Informatics professionals can work in a wide variety of
                industries, including careers such as health informatics,
                business analysis, information architecture, data analysis, and
                user experience design (San Jose State University, n.d.)...
              </p>
              <ul className="actions">
                <li>
                  <Link to="/intro" className="button next" aria-label="Read the Introduction">
                    Read More
                  </Link>
                </li>
              </ul>
            </div>
          </section>
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic01})` }}>
              <header className="major">
                <h3>Competency A</h3>
                <p>
                  Apply technology informatics skills to solve specific industry
                  data and information management problems, with a focus on
                  usability and designing for users
                </p>
              </header>
              <Link to="/comp_a" className="link primary" aria-label="Read Competency A"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h3>Competency B</h3>
                <p>
                  Evaluate, manage, and develop electronic records programs and
                  applications in a specific organizational setting
                </p>
              </header>
              <Link to="/comp_b" className="link primary" aria-label="Read Competency B"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>Competency C</h3>
                <p>
                  Demonstrate strong understanding of security and ethics issues
                  related to informatics, user interface, and inter-professional
                  application of informatics in specific fields by designing and
                  implementing appropriate information assurance and ethics and
                  privacy solutions
                </p>
              </header>
              <Link to="/comp_c" className="link primary" aria-label="Read Competency C"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>Competency D</h3>
                <p>
                  {" "}
                  Identify user needs, ideate informatics products and services,
                  prototype new concepts, and evaluate a prototype’s usability
                </p>
              </header>
              <Link to="/comp_d" className="link primary" aria-label="Read Competency D"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic05})` }}>
              <header className="major">
                <h3>Competency E</h3>
                <p>
                  Work collaboratively in teams and use project management
                  practices effectively to solve user-centric information and
                  data problems
                </p>
              </header>
              <Link to="/comp_e" className="link primary" aria-label="Read Competency E"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic06})` }}>
              <header className="major">
                <h3>Competency F</h3>
                <p>
                  Conduct informatics analysis and visualization applied to
                  different real-world fields, such as health science and
                  privacy.
                </p>
              </header>
              <Link to="/comp_f" className="link primary" aria-label="Read Competency F"></Link>
            </article>
          </section>
          <section id="conclusion">
            <div className="inner">
              <header className="major">
                <h2>Conclusion and Affirmation</h2>
              </header>
              <p>
                I discovered the MS in Informatics program at SJSU while searching online for graduate programs from established brick and mortar universities. I had worked professionally as a UI designer for many years and became interested in studying user experience design after working with many talented UX professionals. I wanted to find a graduate program that had a foundational emphasis on user experience with a technical focus on data science and information management...
              </p>
              <ul className="actions">
                <li>
                  <Link to="/conclusion" className="button next" aria-label="Read the Conclusion and Affirmation">
                    Read More
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
